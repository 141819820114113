<template>
  <default-layout>
    <nav class="education__nav">
      <ul>
        <li @click="changeUrl('lecturer')">
          {{ $t("research.nav.lecturer") }}
        </li>
        <li @click="changeUrl('student')">
          {{ $t("research.nav.student") }}
        </li>
        <li id="publish">
          {{ $t("research.nav.publish") }}
          <ul id="publish__submenu">
            <li @click="changeUrl('international')">
              {{ $t("research.nav.international") }}
            </li>
            <li @click="changeUrl('national')">
              {{ $t("research.nav.national") }}
            </li>
          </ul>
        </li>

        <li @click="changeUrl('book')">
          {{ $t("research.nav.book") }}
        </li>
      </ul>
      <!-- <input type="search" /> -->
    </nav>

    <div style="padding: 50px; background-color: #f4f7fa">
      <div class="research__table__container custom-scrollbar">
        <div v-html="currentHtmlContent"></div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";

export default {
  data() {
    return {
      currentHtmlContent: "",
    };
  },
  methods: {
    toResearch(path) {
      this.$router.push(path);
    },
    async changeUrl(program) {
      this.$swal({
        title: "Loading",
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 10000,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });
      try {
        const response = await this.$axios.get(
          `/api/jsxlsx/research/${program}`
        );
        this.currentHtmlContent = response.data;

        this.$swal.close();
      } catch (err) {
        this.$swal.close();
      }
    },
  },
  async created() {
    this.changeUrl("international");
  },
  components: {
    DefaultLayout,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/research.scss";
</style>